import { get, post } from 'redux-bees';

const s2Endpoints = apiPath => ({
  createS2Integration: {
    method: post,
    path: `${apiPath}/s2integration/new`
  },
  updateS2Integration: {
    method: post,
    path: `${apiPath}/s2integration/update`
  },
  getS2View: {
    method: get,
    path: `${apiPath}/s2integration/view`
  },
  getS2Users: {
    method: post,
    path: `${apiPath}/s2integration/users`
  }
});

export default s2Endpoints;
