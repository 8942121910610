import React, { useEffect, useState } from 'react';
import { Modal, Button, Tooltip, Progress } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import STable from '../../components/Standard/STable';
import SText from '../../components/Standard/SText';
import ClientInteractionLink from '../../components/ClientInteractionLink';
import { aiSettingsResource } from '../../redux/resources/aiSettings';
import { CLIENT_INTERACTIONS_TYPES, COMMUNICATION_TYPES } from '../../core/utils/constants';
import {
  checkExportStatus,
  downloadFileAfterExport
} from '../../redux/entities/exports/operations';

const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}).*/;

const TooltipText = ({ title, children }) => (
  <Tooltip title={title} placement="topLeft">
    <SText>{children}</SText>
  </Tooltip>
);

const ModalChecklistDefinitions = ({
  isModalOpen,
  setIsModalOpen,
  loadChecklistDefinitions,
  statusChecklistDefinitions,
  taskId,
  organizationId
}) => {
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10
  });
  const [checklistDefinitions, setChecklistDefinitions] = useState([]);
  const { t } = useTranslation();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const loadChecklistDefinitionData = async (page, pageSize) => {
    setLoading(true);
    setChecklistDefinitions([]);
    await loadChecklistDefinitions({
      communications_status: statusChecklistDefinitions,
      task_id: taskId,
      page_number: page,
      page_size: pageSize
    }).then(resource => {
      if (resource) {
        const camelCaseMeta = {
          totalCount: resource?.meta['total-count'],
          totalPages: resource?.meta['total-pages'],
          page: resource?.meta.page
        };
        const responseData = resource?.data;
        for (const key in responseData) {
          if (responseData[key].hasOwnProperty('attributes')) {
            const { attributes } = responseData[key];
            for (const attrKey in attributes) {
              responseData[key][attrKey] = attributes[attrKey];
            }
            delete responseData[key].attributes;
          }
        }
        setChecklistDefinitions(responseData);
        setPagination({
          current: camelCaseMeta.page,
          pageSize,
          total: camelCaseMeta.totalPages
        });
      } else {
        setIsModalOpen(false);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    if (statusChecklistDefinitions && taskId) {
      loadChecklistDefinitionData(1, pagination.pageSize);
    }
  }, [statusChecklistDefinitions, taskId]);

  const onPaginationChange = pagination => {
    const { pageSize, current } = pagination;
    loadChecklistDefinitionData(current, pageSize);
  };
  const handleExportChecklistDefinitions = async () => {
    try {
      const res = await dispatch(
        aiSettingsResource.operations.exportCommunicationTablePointwise({
          status: statusChecklistDefinitions,
          task_id: taskId,
          organization_id: organizationId
        })
      );

      let exportIntervalId;
      let exportStatus = {};
      let exportModal;
      const exportModalTwo = () => {
        clearInterval(exportIntervalId);
        const modalInstance = confirm({
          title: t('clientInteractionsPage.exportModal.title'),
          okText: t('clientInteractionsPage.exportModal.ok'),
          cancelText: t('clientInteractionsPage.exportModal.cancelWait'),
          content: t('clientInteractionsPage.exportModal.contentBreak'),
          onOk: exportHandleOk,
          onCancel: () => exportHandleCancel(modalInstance)
        });

        return modalInstance;
      };

      const exportHandleOk = () => {
        clearInterval(exportIntervalId);
        Modal.destroyAll();
      };
      const exportHandleCancel = async modalInstance => {
        modalInstance.destroy();
        if (exportIntervalId) {
          clearInterval(exportIntervalId);
        }
        exportModal = confirm({
          confirmLoading: true,
          title: t('clientInteractionsPage.exportModal.title'),
          autoFocusButton: null,
          okButtonProps: { style: { display: 'none' } },
          cancelText: t('clientInteractionsPage.exportModal.cancel2'),
          content: <Progress percent={exportStatus.progress} status="active" />,
          onCancel: () => exportModalTwo()
        });

        exportIntervalId = setInterval(async () => {
          exportStatus = await checkExportStatus({
            body: {
              requestId: res.requestId
            },
            errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
          }).catch(() => {
            clearInterval(exportIntervalId);
            Modal.destroyAll();
          });

          if (exportStatus.progress === 100 || exportStatus.status === 'done') {
            clearInterval(exportIntervalId);
            exportModal.update({
              confirmLoading: false,
              content: <Progress percent={exportStatus.progress} status="success" />
            });
            await downloadFileAfterExport({
              body: {
                requestId: res.requestId
              },
              errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
            }).catch(() => {
              exportModal.update({
                content: <Progress percent={exportStatus.progress} status="exception" />
              });
              Modal.destroyAll();
            });
            Modal.destroyAll();
          } else {
            exportModal.update({
              content: <Progress percent={exportStatus.progress} status="active" />
            });
          }
        }, 2000);
      };
      exportModal = confirm({
        confirmLoading: true,
        title: t('clientInteractionsPage.exportModal.title'),
        autoFocusButton: null,
        okButtonProps: { style: { display: 'none' } },
        cancelText: t('clientInteractionsPage.exportModal.cancel2'),
        content: <Progress percent={exportStatus.progress} status="active" />,
        onCancel: () => exportModalTwo()
      });

      exportIntervalId = setInterval(async () => {
        exportStatus = await checkExportStatus({
          body: {
            requestId: res.requestId
          },
          errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
        }).catch(() => {
          clearInterval(exportIntervalId);
          Modal.destroyAll();
        });
        if (exportStatus.progress === 100 || exportStatus.status === 'done') {
          clearInterval(exportIntervalId);
          exportModal.update({
            confirmLoading: false,
            content: <Progress percent={exportStatus.progress} status="success" />
          });
          await downloadFileAfterExport({
            body: {
              requestId: res.requestId
            },
            errorMessageKey: 'clientInteractionsPage.drawer.tables.messages.exportTableFailed'
          }).catch(() => {
            exportModal.update({
              content: <Progress percent={exportStatus.progress} status="exception" />
            });
            Modal.destroyAll();
          });
          Modal.destroyAll();
        } else {
          exportModal.update({
            content: <Progress percent={exportStatus.progress} status="active" />
          });
        }
      }, 2000);
    } catch (e) {
      Modal.destroyAll();
    }
  };

  const tableConfig = {
    columns: [
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 30,
        render: (id, record) => {
          return (
            <ClientInteractionLink
              id={record?.review_id ? record.review_id : id}
              communication={{
                communicationType:
                  record?.communication_type === 'ticket'
                    ? COMMUNICATION_TYPES.TEXT
                    : COMMUNICATION_TYPES.PHONE_CALL,
                clientInteractionType: record?.review_id
                  ? 'reviews'
                  : record?.communication_type === 'ticket'
                  ? CLIENT_INTERACTIONS_TYPES.TICKET
                  : CLIENT_INTERACTIONS_TYPES.PHONE_CALL
              }}
              useDrawer={false}
              isNewPage
            />
          );
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.dateTime'),
        dataIndex: 'started_at',
        key: 'started_at',
        fixed: 'left',
        render: startedAt => {
          const startedAtStr = new Date(startedAt);
          const timezoneOffset = startedAtStr.getTimezoneOffset();
          const localStartedAt = new Date(
            startedAtStr.getTime() - timezoneOffset * 60000
          ).toLocaleString();
          return <TooltipText title={localStartedAt}>{localStartedAt}</TooltipText>;
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.phoneNumber'),
        dataIndex: 'phone_number',
        key: 'phone_number',
        align: 'center',
        render: phoneNumber => <TooltipText title={phoneNumber}>{phoneNumber}</TooltipText>
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.direction'),
        dataIndex: 'direction',
        key: 'direction',
        align: 'center',
        render: direction => {
          const dataDirection = direction === 'incoming' ? 'Входящий' : 'Исходящий';
          return <TooltipText title={dataDirection}>{dataDirection}</TooltipText>;
        }
      },
      {
        title: t('aiSettingsPage.tableColumnsTitle.duration'),
        dataIndex: 'duration',
        key: 'duration',
        align: 'center',
        render: duration => {
          return <TooltipText title={duration}>{duration}</TooltipText>;
        }
      }
    ]
  };
  return (
    <Modal
      title={t('aiSettingsPage.taskSettings.communicationList')}
      centered
      visible={isModalOpen}
      onCancel={closeModal}
      footer={null}
      width={755}
    >
      <STable
        dataSource={checklistDefinitions}
        rowKey="id"
        size="small"
        loading={loading}
        columns={tableConfig.columns}
        border
        pagination={{
          current: pagination.current,
          total: pagination.total * pagination.pageSize,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={onPaginationChange}
      />
      <Button
        key="export"
        type="primary"
        style={{ float: 'left', top: '-28px' }}
        onClick={handleExportChecklistDefinitions}
      >
        Экспортировать
      </Button>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {};

const mapDispatchToProps = {
  loadChecklistDefinitions: aiSettingsResource.operations.loadChecklistDefinitions
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalChecklistDefinitions);
