import React from 'react';
import { INTEGRATIONS } from 'core/utils/constants';
import { integrationsResource } from 'redux/resources/integrations';
import api, { s2SynchronizationEndpoint } from 'core/api';
import { Col, Modal, Row, Spin, Typography } from 'antd';
import { saveBackgroundJob } from '../backgroundJob/operations';
import parseOptionsForApi from '../../../core/utils/parseOptionsForApi';

let integration = null;
let errorInstall = true;
const modal = Modal;
export const createS2Integration = ({
  name,
  endpoint,
  clientSecret,
  username,
                                      accessToken,
  organization_id
}) => async dispatch => {
  try {
    const integration = await dispatch(
      integrationsResource.operations.create({
        name,
        endpoint,
        clientSecret,
        organizationId: organization_id,
        integrationType: INTEGRATIONS.s2.type
      })
    );
    dispatch(saveBackgroundJob(integration?.currentBackgroundJob));

    return integration?.id;
  } catch (error) {
    console.log(error);
  }
};

export const syncDataWithS2 = ({ id, fetchFrom }) => async dispatch => {
  await fetch(`${s2SynchronizationEndpoint}?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      integration_id: id,
      fetch_from: fetchFrom
    })
  });
};
//
// export const getStatusConnectEdna = async id => {
//   try {
//     const response = await api.getStatusConnectEdna(decamelizeKeys({ integrationId: id }));
//     return response.body;
//   } catch (error) {}
// };
// export const getEdnaDb = async id => {
//   try {
//     const response = await api.getEdnaDb(decamelizeKeys({ id }));
//     const { data } = response.body;
//     const result = {
//       username: data?.username,
//       password: decryptedData(data?.password)
//     };
//     return result;
//   } catch (error) {}
// };
// export const setEdnaDb = async (id, otherFields) => {
//   try {
//     const response = await api.setEdnaDb(
//       { integration_id: id },
//       decamelizeKeys({
//         username: otherFields.username,
//         password: otherFields.password
//       })
//     );
//     const resources = processJsonApiCollection(response.body.data);
//     return resources;
//   } catch (error) {
//     message.error(error);
//   }
// };
//
// export const getEntityCustomFieldsEdna = ({ id }) => async dispatch => {
//   try {
//     const response = await api.getEntityCustomFieldsEdna(decamelizeKeys({ integrationId: id }));
//     const resources = processJsonApiCollection(response.body.data);
//     return resources;
//   } catch (error) {}
// };
// export const connectCustomFieldEdna = ({ fieldId }) => async dispatch => {
//   try {
//     await api.connectEntityCustomFieldsEdna(parseOptionsForApi({ fieldId }));
//     return true;
//   } catch (error) {}
// };
//
// export const connectAllCustomFieldsEdna = ({ id, all }) => async dispatch => {
//   try {
//     await api.connectAllEntityCustomFieldsEdna(
//       parseOptionsForApi({ integrationId: id, connect: all })
//     );
//     return true;
//   } catch (error) {}
// };
//
// export const disconnectCustomFieldEdna = ({ fieldId }) => async dispatch => {
//   try {
//     await api.disconnectEntityCustomFieldsEdna(parseOptionsForApi({ fieldId }));
//     return true;
//   } catch (error) {}
// };
