export default {
  constants: {
    accessLevels: {
      noInvitationSent: 'Приглашение не отправлено',
      invitationSent: 'Приглашение отправлено',
      invitationAccepted: 'Вход выполнен',
      userBlocked: 'Нет доступа'
    },
    plans: {
      free: 'Бесплатный',
      start: 'Старт',
      profi: 'Профи',
      notInstalled: 'Не установлен'
    },
    plansDescription: {
      checklistConstructor: 'Конструктор форм оценки',
      omnichannelReviewsCount100: '100 омниканальных оценок (звонки, чаты, email, тикеты) в месяц',
      omnichannelReviews: 'Омниканальные оценки (звонки, чаты, email, тикеты)',
      dashboardsCount2: '2 дашборда',
      unlimitedDashboards: 'Неограниченное количество дашбордов',
      communicationPanel: 'Панель коммуникаций',
      library: 'Библиотека звонков',
      emailNotification: 'Email уведомления',
      appNotifications: 'Уведомления в приложении ',
      dashboardsShare: 'Совместное использование дашбордов',
      communicationsTableShare: 'Совместное использование панели коммуникаций',
      calibrations: 'Калибровочные сессии',
      unlimitedTags: 'Неограниченное количество автоматических тегов',
      unlimitedIntegrations: 'Неограниченное количество интеграций',
      integrationsCount1: '1 CRM или VoIP интеграция'
    },
    integrations: {
      customHttpTelephony: { title: 'API-телефония', description: 'Интеграция с програмными АТС' },
      customHttpText: {
        title: 'API для текстовых коммуникаций',
        description: 'Интеграция с текстовыми каналами'
      },
      bitrixCrm: { title: 'Bitrix24', description: 'Интеграция Bitrix 24 CRM' },
      amoCrm: { title: 'AmoCRM', description: 'Интеграция AmoCRM' },
      sipuni: { title: 'Sipuni', description: 'Интеграция c Sipuni' },
      mango: {
        title: 'Mango',
        description: 'Интеграция c Mango'
      },
      uis: { title: 'UIS/Comagic', description: 'Интеграция c UIS/Comagic' },
      naumen: {
        title: 'Naumen',
        description: 'Интеграция с Naumen'
      }
    },
    integrationsSyncPeroids: {
      month: 'Месяц',
      threeMonth: 'Три месяца',
      sixMonth: 'Пол года',
      allTime: 'Все время'
    },
    taskDefinitionTypes: {
      automatic: 'Автоматический',
      manual: 'Ручной'
    },
    userStatuses: {
      active: 'Активен',
      disabled: 'Неактивен'
    },
    permissionsDescriptions: {
      canPerformClientInteraction: {
        title: 'Сотрудник коммуницирует с клиентами (звонки, чаты, тикеты, email)',
        description: ''
      },
      canSignIn: {
        title: 'Сотрудник имеет доступ в Qolio',
        description: ''
      },
      canSeeAndManageHiddenComments: {
        title: 'Добавлять и видеть скрытые комментарии',
        description: ''
      },
      canAccessUserAnalyticsPageChartsTab: {
        title: 'Доступ к ЛК',
        description: 'Сотрудник имеет доступ к странице персональной аналитики'
      },
      canAccessUserAnalyticsPageReviewsTab: {
        title: 'Доступ к персональному списку оценок в личном кабинете',
        description: 'Сотрудник видит список проверенных коммуникаций и комментариев к ним'
      },
      canAccessUserAnalyticsPageCommentsTab: {
        title: '',
        description: ''
      },
      canReplyCommnets: {
        title: 'Ответ на комментарии к своим коммуникациям',
        description: 'Сотрудник может отвечать на комментарии к оценкам по его коммуникациям'
      },
      canReplyAnyoneComments: {
        title: 'Ответ на комментарии ко всем коммуникациям',
        description: 'Сотрудник может отвечать на комментарии к оценкам по всем коммуникациям'
      },
      canAccessLibrary: {
        title: 'Доступ к библиотеке звонков',
        description: ''
      },
      canAddLibraryItems: {
        title: 'Добавление файлов в библиотеку',
        description: ''
      },
      canSeeUnitClientInteractions: {
        title: 'Просмотр всех коммуникаций в отделе',
        description: ''
      },
      canDownloadClientInteraction: {
        title: 'Скачивать файл коммуникации',
        description: ''
      },
      canMakeReview: {
        title: 'Право оценивать и комментировать коммуникации в своем отделе',
        description: ''
      },
      canSeeUnitReviews: {
        title: 'Просмотр оценок и комментариев других пользователей',
        description: ''
      },
      canSeeAndUpdateAssignedTasks: {
        title: 'Управление своими задачами',
        description: ''
      },
      canManageTasks: {
        title: 'Добавление и управление созданными задачами',
        description: ''
      },
      canManageUnitTasks: {
        title: 'Добавление и управление задачами отдела',
        description: ''
      },
      canManageLibrary: {
        title: 'Управление библиотекой звонков',
        description: 'Возможность добавлять/удалять/редактировать папки и файлы внутри библиотеки'
      },
      canManageChecklistDefinitions: {
        title: 'Управление формами оценки',
        description: ''
      },
      canManageTaskDefinitions: {
        title: 'Управление тегами',
        description: ''
      },
      canManageOrganizationStructure: {
        title: 'Управление отделами и пользователями',
        description: ''
      },
      canManageOrganizationSettings: {
        title: 'Управление общими настройками организации',
        description: ''
      },
      canManageIntegrations: {
        title: 'Управление интеграциями',
        description: ''
      },
      canSeeDashboards: {
        title: 'Доступ к аналитике',
        description: ''
      },
      canSeeUnitOperatorsAnalyticsPage: {
        title: 'Доступ к ЛК сотрудников, которые коммуницируют с клиентами',
        description: ''
      },
      canAccessTeamPage: {
        title: 'Доступ к странице "Команда"',
        description: ''
      },
      canManageCalibrationSessions: {
        title: 'Доступ к калибровочным сессиям',
        description: ''
      },
      canSeeReviewerName: {
        title: 'Отображение имени проверяющего',
        description: ''
      },
      canManageWorkPlanTaskConfigurations: {
        title: 'Управление распределением нагрузки',
        description: ''
      },
      canCreateAppellations: {
        title: 'Подавать апелляцию',
        description: ''
      },
      canAdministrateAppellations: {
        title: 'Управлять настройками и удалять апелляции',
        description: ''
      },
      canWatchAppellations: {
        title: 'Быть участником в апелляции',
        description: ''
      },
      canSeeUnitAppellations: {
        title: 'Доступ к трекеру апелляций',
        description: ''
      },
      canAccessUserAnalyticsPageOperatorsAppellationsTab: {
        title: 'Доступ к апелляциям через личный кабинет (для операторов)',
        description: ''
      },
      canAccessAppellationsAnalyticsPage: {
        title: 'Доступ к аналитике по апелляциям',
        description: ''
      },
      canUpdateClientInteractionOperator: {
        title: 'Право менять оператора, ответственного за звонок',
        description: ''
      },
      canAiTranscribeClientInteractions: {
        title: 'Сотрудник имеет доступ в функционалу транскрибации',
        description: ''
      },
      canAiAnalyzeClientInteractions: {
        title: 'Сотрудник имеет доступ к разделам AI',
        description: ''
      },
      canEditReview: {
        title: 'Имеет право редактировать любые оценки',
        description: ''
      }
    },
    widgetsDescriptions: {
      checklistDefinitionAverageScore: {
        title: 'Средний балл по форме оценки',
        description:
          'Этот виджет показывает общий средний балл по одной конкретной форме оценки, с помощью которого совершали проверку звонков.'
      },
      phoneCallsCount: {
        title: 'Количество звонков',
        description: 'Этот виджет просто подсчитывает количество звонков.'
      },
      phoneCallsAverageDuration: {
        title: 'Средняя продолжительность звонка',
        description:
          'Этот виджет показывает усредненную продолжительность всех выбранных вами звонков. Формат отображения - мм:сс (минуты : секунды).'
      },
      totalPhoneCallsDuration: {
        title: 'Общая продолжительность звонков',
        description:
          'Этот виджет показывает сумму длительностей звонков, т.е. общую продолжительность все выбранных вами разговоров. Формат отображения - чч:мм:сс (часы : минуты : секунды).'
      },
      reviewedClientInteractionsDuration: {
        title: 'Общая продолжительность проверенных звонков',
        description:
          'Данный виджет отображает суммарную продолжительность всех прошедших проверку звонков. Подсчет ведется по дате оценки.'
      },
      reviewedClientInteractionsPercentage: {
        title: 'Процент проверок',
        description:
          'Этот виджет показывает, какой процент звонков был проверен из общего количества совершенных звонков за выбранный вами период.'
      },
      checklistDefinitionAverageScoreByUnits: {
        title: 'Сравнение среднего балла по отделам',
        description:
          'Этот виджет в виде гистограммы показывает сравнение среднего балла по одной форме оценки в отделах.'
      },
      checklistDefinitionAverageScoreByOperators: {
        title: 'Сравнение среднего балла по сотрудникам',
        description:
          'Этот виджет в виде гистограммы показывает сравнение среднего балла по одной форме оценки у выбранных сотрудников.'
      },
      checklistDefinitionAverageScoreByChecklistDefinitions: {
        title: 'Сравнение среднего балла по формам оценки',
        description:
          'Этот виджет в виде гистограммы показывает сравнение общего среднего балла по разным формам оценки.'
      },
      phoneCallsCountByUnits: {
        title: 'Сравнение кол-ва звонков по отделам',
        description:
          'Этот виджет в виде гистограммы сравнивает количество звонков в разных отделах или командах.'
      },
      phoneCallsCountByOperators: {
        title: 'Сравнение кол-ва звонков по сотрудникам',
        description:
          'Этот виджет в виде гистограммы сравнивает количество звонков у разных сотрудников (в одном отделе ил по компании в целом).'
      },
      reviewsCountByReviewers: {
        title: 'Сравнение кол-ва проверок супервизоров',
        description:
          'Этот виджет в виде гистограммы показывает, сколько проверок совершил каждый супервизор в компании за выбранный период.'
      },
      ratingFlagsCount: {
        title: 'Количество флагов и комментариев',
        description:
          'Этот виджет в виде круговой диаграммы показывает в соотношении, какое количество комментариев с флагами и без было создано за выбранное время в компании в целом или в выбранном вами отделе/команде, или даже у одного конкретного сотрудника.'
      },
      ratingFlagsCountByUnits: {
        title: 'Сравнение кол-ва флагов по отделам',
        description:
          'Этот виджет в виде гистограммы показывает в соотношении, какое количество комментариев с флагами и без было создано в отделах/командах за выбранное время.'
      },
      ratingFlagsCountByOperators: {
        title: 'Сравнение кол-ва флагов по сотрудникам',
        description:
          'Этот виджет в виде гистограммы показывает в соотношении, какое количество комментариев с флагами и без было создано по каждому сотруднику за выбранное время.'
      },
      labelsCountByOperators: {
        title: 'Сравнение кол-ва тегов по сотрудникам',
        description:
          'Этот виджет в виде гистограммы показывает в соотношении, какое количество тегов было присвоено звонкам каждого сотрудника за выбранное время.'
      },
      labelsCountByUnits: {
        title: 'Сравнение кол-ва тегов по отделам',
        description:
          'Этот виджет в виде гистограммы показывает в соотношении, какое количество тегов было присвоено звонкам в отделах/командах за выбранное время.'
      },
      checklistDefinitionQuestionsAverageScores: {
        title: 'Сравнение среднего балла по критериям',
        description:
          'Этот виджет в виде гистограммы показывает сравнение, какой средний балл получился в каждом критерии.'
      },
      checklistDefinitionQuestionsAverageScoresByQuestionGroups: {
        title: 'Сравнение среднего балла критериев в группах',
        description:
          'Этот виджет в виде гистограммы с блоками внутри показывает сравнение, какой средний балл получился в каждом критерии (пункте оценки) каждой группы формы оценки и сравнение групп друг с другом.'
      },
      checklistDefinitionQuestionGroupsAverageScores: {
        title: 'Сравнение среднего балла по группам критериев',
        description:
          'Этот виджет в виде гистограммы показывает сравнение среднего балла по группам критериев.'
      },
      phoneCallsAverageDurationByUnits: {
        title: 'Сравнение средней продолжительности звонков по отделам',
        description:
          'Этот виджет в виде гистограммы сравнивает среднюю продолжительность всех звонков в каждом отделе. Формат отображения - мм:сс (минуты : секунды).'
      },
      phoneCallsAverageDurationByOperators: {
        title: 'Сравнение средней продолжительности звонков по сотрудникам',
        description:
          'Этот виджет в виде гистограммы сравнивает среднюю продолжительность всех звонков по каждому сотруднику. Формат отображения - мм:сс (минуты : секунды).'
      },
      reviewsCountByReviewersHistory: {
        title: 'Динамика кол-ва проверенных звонков',
        description:
          'Этот виджет в виде диаграммы показывает, сколько проверок совершил каждый супервизорв определенный отрезок времени.'
      },
      checklistDefinitionAverageScoreByOperatorsHistory: {
        title: 'Динамика среднего балла по сотрудникам',
        description:
          'Этот виджет в виде диаграммы показывает изменение среднего балла по одной форме оценки у разных сотрудников в каждый отрезок времени.'
      },
      checklistDefinitionAverageScoreByUnitsHistory: {
        title: 'Динамика среднего балла по отделам',
        description:
          'Этот виджет в виде диаграммы показывает изменение среднего балла по одной форме оценки в разных отделах или командах в каждый отрезок времени.'
      },
      averageScoreByQuestionsHistory: {
        title: 'Динамика балла по критериям формы оценки',
        description:
          'Этот виджет в виде диаграммы показывает изменение среднего балла по каждому критерию в форме оценки в каждый отрезок времени.'
      },
      averageScoreByQuestionGroupsHistory: {
        title: 'Динамика балла по группам критериев',
        description:
          'Этот виджет в виде диаграммы показывает изменение среднего балла по группам критериев в форме оценки в каждый отрезок времени.'
      },
      phoneCallsAverageDurationByOperatorsHistory: {
        title: 'Динамика средней продолжительности звонка по сотрудникам',
        description:
          'Этот виджет в виде графика показывает изменение средней продолжительности звонков у каждого сотрудника в определенные отрезки времени. Формат отображения шкалы Y - мм:сс (минуты : секунды).'
      },
      phoneCallsAverageDurationByUnitsHistory: {
        title: 'Динамика средней продолжительности звонка по отделам',
        description:
          'Этот виджет в виде графика показывает изменение средней продолжительности звонков в каждом отделе или команде в определенные отрезки времени. Формат отображения шкалы Y - мм:сс (минуты : секунды).'
      },
      clientInteractionsCountByOperatorsHistory: {
        title: 'Динамика количества звонков по сотрудникам',
        description:
          'Этот виджет в виде диаграммы показывает изменение количества звонков у каждого сотрудникав каждый отрезок времени.'
      },
      clientInteractionsCountByUnitsHistory: {
        title: 'Динамика количества звонков по отделам',
        description:
          'Этот виджет в виде диаграммы показывает изменение количества звонков в разных отделах или командах. в каждый отрезок времени.'
      },
      tableReviewsCountByOperators: {
        title: 'Результаты проверок по сотрудникам',
        description:
          'Виджет отображает средний балл по сотруднику на конкретную дату, итоговый балл за месяц и количество проверок'
      },
      tableReviewsCountByOperatorsWithKpi: {
        title: 'KPI по операторам',
        description:
          'Показывает среднюю оценку по операторам за последние пять недель, а также дополнительную таблицу на основе настроек KPI'
      },
      tableChecklistItemsByOperators: {
        title: 'Результаты сотрудников по критериям',
        description:
          'Виджет отображает средней балл по критериям или группам критериев по операторам'
      },
      tableChecklistItemsHistory: {
        title: 'Результаты проверок по критериям',
        description:
          'Виджет отображает средний балл по вопросу или группе вопросов на конкретную дату, итоговый балл за месяц и количество проверок'
      },
      flagsAndComments: {
        title: 'Флаги и комментарии',
        description:
          'Виджет отображает количество флагов и комментариев по операторам, по отделам и по компании'
      },
      tableChecklistsScoresByOperators: {
        title: 'Результаты проверок по формам оценки',
        description:
          'Виджет отображает результаты проверок сотрудников или отделов по выбранным формам оценки за установленный период'
      },
      tableReviewsCountByReviewers: {
        title: 'Количество проверок по проверяющим',
        description:
          'Виджет отображает количество проверок по проверяющим по выбранным формам оценки за установленный период'
      },
      tableReviewsWithScores: {
        title: 'Результаты сотрудников по форме оценки',
        description:
          'Виджет отображает проверки по сотрудникам, вместе с результатами проверки по критериям, и группам критериев в форме оценки'
      }
    },
    reviewCallStatuses: {
      all: 'Все',
      checked: 'Оцененные',
      unchecked: 'Не оцененные'
    },
    callsDirections: {
      all: 'Все',
      incoming: 'Входящие',
      outcoming: 'Исходящие'
    },
    weekendViewStatuses: {
      view: 'Показывать',
      hide: 'Не показывать'
    },
    communicationDirections: {
      incoming: 'Входящая',
      outcoming: 'Исходящая'
    },
    sessionsDirections: {
      pending: 'Предстоящие',
      done: 'Прошедшие'
    },
    reviewStatuses: {
      all: 'Все',
      reviewed: 'Проверен',
      notReviewed: 'Не проверен'
    },
    locales: {
      ru: 'Русский',
      en: 'Английский',
      es: 'Испанский',
      it: 'Итальянский'
    },
    clientInteractionDirections: {
      all: 'Все',
      incoming: 'Входящий',
      outcoming: 'Исходящий',
      unknown: 'Не установлен'
    },
    ratingMethods: {
      simple: 'Простая оценка',
      weighted: 'Взвешенная оценка'
    },
    ratingCalculations: {
      sum: 'Сумма баллов',
      average: 'Средний балл'
    },
    ratingModes: {
      numbers: 'Числовое',
      percentage: 'Процентное'
    },
    autofailEnabled: {
      true: 'Да',
      false: 'Нет'
    },
    clientInteractionTypes: {
      chat: 'Чаты',
      ticket: 'Тикеты',
      phone_call: 'Звонки',
      email: 'Письма',
      other: 'Другое'
    },
    flagsColors: {
      red: 'Красный флаг',
      green: 'Зеленый флаг',
      yellow: 'Желтый флаг',
      withoutFlag: 'Без флага'
    },
    columnTypes: {
      communicationType: {
        title: 'Тип'
      },
      clientPhoneNumber: {
        title: 'Номер телефона'
      },
      startedAt: {
        title: 'Дата и время коммуникации',
        columnName: 'Дата и время'
      },
      nps: {
        title: 'NPS'
      },
      direction: {
        title: 'Направление'
      },
      text_conversation_response_time_in_seconds: {
        title: 'Время ответа'
      },
      email: {
        title: 'Email'
      },
      clientId: {
        title: 'ID клиента'
      },
      chatId: {
        title: 'Номер чата'
      },
      ticketId: {
        title: 'Номер тикета'
      },
      partsCount: {
        title: 'Количество сообщений',
        columnName: 'Кол-во сообщ.'
      },
      duration: {
        title: 'Продолжительность',
        columnName: 'Прод-ть'
      },
      text_conversation_ended_at: {
        title: 'Дата и время закрытия'
      },
      unitId: {
        title: 'Отдел'
      },
      operatorId: {
        title: 'ФИО'
      },
      isReviewed: {
        title: 'Статус'
      },
      score: {
        title: 'Результат оценки',
        columnName: 'Оценка'
      },
      checklistDefinitionName: {
        title: 'Форма оценки'
      },
      reviewReviewerId: {
        title: 'ФИО оценившего'
      },
      reviewCreatedAt: {
        title: 'Дата оценки'
      },
      reviewComments: {
        title: 'Флаги и комментарии'
      },
      taskTaskDefinitions: {
        title: 'Теги'
      },
      reviewTasksCount: {
        title: 'Задачи'
      },
      taskPriority: {
        title: 'Приоритет задачи'
      },
      taskStatus: {
        title: 'Статус задачи'
      },
      taskAssigneeId: {
        title: 'Ответственный в задаче'
      },
      status: {
        title: 'Статус контакта'
      },
      crmEntityId: {
        title: 'Сделка/Тикет'
      },
      createdAt: {
        title: 'Дата создания'
      },
      updatedAt: {
        title: 'Дата обновления'
      }
    },
    boolValues: {
      true: 'Да',
      false: 'Нет'
    },
    colorPercantageZone: {
      emptyPercantage: 'Не попавшие в зону оценки',
      lowPercantage: 'Критично',
      mediumPercantage: 'Нормально',
      highPercantage: 'Отлично'
    },
    clientInteractionsTypes: {
      phoneCall: 'Звонок',
      email: 'Email',
      chat: 'Чат',
      ticket: 'Тикет',
      video: 'Видео',
      other: 'Другое'
    },
    symbolicTimeRanges: {
      all: 'Все время',
      today: 'Сегодня',
      yesterday: 'Вчера',
      lastSevenDays: 'Последние 7 дней',
      thisWeek: 'Текущая неделя',
      previousWeek: 'Прошлая неделя',
      lastThirtyDays: 'Последние 30 дней',
      thisMonth: 'Текущий месяц',
      previousMonth: 'Прошлый месяц',
      thisYear: 'Текущий год',
      previousYear: 'Прошлый год'
    },
    symbolicMode: {
      clientInteraction: ', по дате коммуникации',
      review: ', по дате оценки'
    },
    notificationsScheduleTypes: {
      immediately: 'Сразу',
      endOfDay: 'В конце дня',
      endOfWeek: 'В конце недели',
      dayAbout: 'Через день'
    },
    notificationsTypes: {
      reviewCreated: 'Оценен звонок',
      replyCommentCreated: 'Ответ на комментарий',
      taskCreated: 'Добавлен автоматический тег'
    },
    syncDataPeriods: {
      threeDays: 'Три дня',
      week: 'Неделя',
      twoWeeks: 'Две недели',
      month: 'Месяц',
      threeMonths: 'Три месяца',
      sixMonths: 'Полгода',
      allTime: 'Всё время'
    },
    colorZonesText: {
      low:
        'Оператор допустил грубые ошибки в этом параметре оценки, которые повлекут за собой репутационные/финансовые потери компании или интерес клиента уже не вернуть',
      medium:
        'Оператор допустил незначительные ошибки в этом пункте оценки, которые не сильно отразятся на репутации компании или желании клиента приобрести товар; по этому параметру оценку стоит подтянуть навыки оператора, т. к. здесь можно лучше и в стандартах это описано.',
      high:
        'Оператор предвосхитил ожидания клиента, сделал все так, что у клиента даже не появилось возможности высказать сомнение; достойный кейс по обработке возражения/сомнения/негатива'
    },
    scaleTypes: {
      max_2: {
        text: {
          yes: 'Да',
          no: 'Нет'
        },
        name: 'Шкала из двух оценок (0/1, да/нет)',
        valuesOptions: {
          emojis: '👍 и 👎 (👎 - 0, 👍 - 1)',
          text: 'Да и Нет (Нет - 0, Да - 1)',
          numbers: '0 и 1'
        }
      },
      max_3: {
        name: 'Шкала из трех оценок (1, 2, 3)',
        valuesOptions: {
          text: '1, 2 и 3'
        }
      },
      max_4: {
        name: 'Шкала из четырех оценок (1, 2, 3, 4)'
      },
      max_5: {
        name: 'Шкала из пяти оценок (1, 2, 3, 4, 5)'
      },
      max_10: {
        name: 'Шкала из десяти оценок (от 1 до 10)',
        valuesOptions: {
          numbers: 'от 1 до 10'
        }
      },
      custom: {
        name: 'Произвольная шкала'
      },
      custom_with_views: {
        name: 'Произвольная шкала (текстовое значение)'
      },
      custom_binary: {
        name: 'Шкала из 2-х оценок с произвольными числами',
        text: {
          yes: 'Да',
          no: 'Нет'
        },
        valuesOptions: {
          emojis: '👍 и 👎',
          text: 'Да и Нет',
          numbers: 'Произвольные числа'
        }
      }
    },
    checklistDefinitionStatuses: {
      draft: 'Черновик',
      archived: 'В архиве',
      published: 'Опубликовано'
    },
    intercomMessengerStatuses: {
      show: 'Показывать',
      hide: 'Скрыть'
    },
    widgetViewModes: {
      operators: 'Сотрудники',
      reviewers: 'Сотрудники',
      units: 'Отделы',
      questions: 'Критерии',
      groups: 'Группы',
      table: 'Таблица',
      chart: 'График',
      score: 'Баллы',
      percantage: 'Проценты'
    },
    datesViewFormat: {
      day: 'День',
      week: 'Неделя',
      month: 'Месяц',
      year: 'Год'
    },
    errors: {
      unknownError:
        'Что-то пошло не так, но мы работаем над этим. Попробуйте перезагрузить страницу',
      loadingRecordError: {
        title: 'Qolio не может получить доступ записи',
        content:
          'Возможно запись была удалена. Пожалуйста обратитесь со ссылкой на запись в поддержку CRM или телефонии.',
        tooltip: {
          copy: 'Скопировать',
          copied: 'Скопировано!'
        }
      }
    },
    defaultPageTitle:
      'Облачный сервис автоматизации контроля качества для контакт-центров и отделов продаж | qolio.ru',
    hiddenNamePlaceholder: 'Неизвестный пользователь',
    workPlanTaskConfigurationsStatuses: {
      draft: 'Черновик',
      pending: 'В ожидании',
      inProgress: 'В процессе',
      completed: 'Завершена',
      stopped: 'Остановлена'
    },
    periodRepeat: {
      day: 'Ежедневно',
      week: 'Еженедельно',
      month: 'Ежемесячно',
      never: 'Не повторять'
    },
    timeUnit: {
      day: 'дней',
      week: 'недель',
      month: 'месяцев'
    },
    appealsStatuses: {
      pending: 'Не обработана',
      inProgress: 'В работе',
      needsInformation: 'Требуется информация',
      accepted: 'Принята',
      rejected: 'Отклонена',
      withoutAnswer: 'Нет ответа'
    },
    appealPersonFilterValues: {
      assignedTo: 'Исполнитель',
      watcher: 'Наблюдатель',
      appellant: 'Подал апелляцию'
    },
    datasetStatuses: {
      done: 'Завершена',
      process: 'В процессе',
      error: 'Ошибка'
    }
  }
};
