import DOMPurify from 'dompurify';
import { isString } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-awesome-lightbox';
import SButton from '../../Standard/SButton';
import SText from '../../Standard/SText';
import SCol from '../../Standard/SCol';
import SRow from '../../Standard/SRow';
import Audio from '../../Audio';

const HtmlContent = styled.span`
  font-size: 16px;
`;

const PartContent = ({ children = '', contentType = '' }) => {
  let htmlString;
  const { t } = useTranslation();

  if (isString(contentType) && contentType.includes('html')) {
    htmlString = children;
  } else {
    // By default we think that it is text content type
    htmlString = children?.replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  htmlString = htmlString
    ?.replace('<i class="hde-uncheckbox"></i>', t('general.no'))
    ?.replace('<i class="hde-checkbox"></i>', t('general.yes'));

  const [visible, setVisible] = useState(false);

  const onClose = e => {
    e.stopPropagation();
    setVisible(false);
  };

  const show = () => {
    setVisible(true);
  };

  const partContent = () => {
    if (contentType === 'image') {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlString;
      const link = tempDiv.querySelector('a');
      const hrefValue = link ? link.href : null;

      return (
        <SCol
          background="var(--gray_primary)"
          borderRadius="16px"
          margin="2px 2px"
          padding="6px 12px !important"
          maxWidth="80%"
          border="1px solid #c2c4cc"
          onClick={e => {
            e.stopPropagation();
            show();
          }}
          width="fit-content"
        >
          <SRow display="flex" justify="space-between" align="middle" wrap={false}>
            <SCol flex="auto" width="100%" className="truncated">
              <SButton
                height="21px"
                display="flex"
                alignItems="center"
                fontWeight="500"
                padding="0"
                type="link"
                fontSize="14px"
                width="100%"
              >
                <SText ellipsis type="primary" color="var(--blue_6)">
                  {hrefValue}
                </SText>
              </SButton>
            </SCol>
          </SRow>
          {visible && (
            <Lightbox
              images={[{ title: hrefValue, url: hrefValue }]}
              startIndex={0}
              onClose={onClose}
            />
          )}
        </SCol>
      );
    }
    if (contentType === 'file') {
      return (
        <a href={htmlString} title="Просмотреть PDF" target="_blank" rel="noopener noreferrer">
          Просмотреть PDF файл
        </a>
      );
    }
    if (contentType === 'audio') {
      return <Audio audioLink={htmlString} />;
    }
    return (
      <HtmlContent
        className="ant-typography"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(htmlString, {
            ALLOWED_ATTR: ['target', 'href', 'src', 'style']
          })
        }}
      />
    );
  };

  return partContent();
};

export default React.memo(PartContent);
