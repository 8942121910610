import React, { useCallback, useEffect, useRef, useState } from 'react';
import Player from 'react-player';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { downloadCallFromMediaUrl } from 'core/api';
import { SPlayer } from './styled';
import AudioTrack from './AudioTrack';
import AudioControls from './AudioControls';

const Audio = ({ audioLink }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const player = useRef();
  const [blobAudio, setBlobAudio] = useState(null);
  const [startGetMedia, setStartGetMedia] = useState(true);
  const [bufferedPercentage, setBufferedPercentage] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlayed, setWasPlayed] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [forcePlayed, setForcePlayed] = useState(false);
  const [duration, setDuration] = useState(0);

  const setTime = useCallback(
    seconds => {
      if (player.current && player.current.seekTo) {
        player.current.seekTo(seconds, 'seconds');
      }
    },
    [player?.current]
  );

  useEffect(() => {
    const setInitState = async () => {
      const { search } = history.location;
      if (search) {
        const { t } = qs.parse(search, { ignoreQueryPrefix: true });
        if (t) {
          setTime(parseFloat(t));
          setWasPlayed(false);
          setPlayedSeconds(parseFloat(t));
        }
      }
    };
    setInitState();
  }, [history.location]);

  useEffect(() => {
    if (forcePlayed) {
      setTime(playedSeconds);
      setForcePlayed(false);
    }
  }, [playedSeconds]);

  const handleProgress = useCallback(
    async ({ playedSeconds, played, loaded }) => {
      if (playedSeconds === 0 && !wasPlayed) {
        return;
      }
      await setPlayedSeconds(playedSeconds);
      setBufferedPercentage(loaded * 100);
    },
    [dispatch]
  );

  const fetchMediaData = async () => {
    try {
      const response = await fetch(downloadCallFromMediaUrl, {
        method: 'POST',
        body: JSON.stringify({ media_url: audioLink })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      await setBlobAudio(URL.createObjectURL(blob));
      setStartGetMedia(false);
    } catch (e) {
      console.log('error', e);
    }
  };

  const clearState = () => {
    setBlobAudio(null);
    setStartGetMedia(true);
    setBufferedPercentage(0);
    setPlaybackRate(1);
    setIsPlaying(false);
    setWasPlayed(false);
    setPlayedSeconds(0);
    setForcePlayed(false);
    setDuration(0);
  };

  useEffect(() => {
    fetchMediaData();
    return () => {
      clearState();
    };
  }, [audioLink]);

  const onPlay = () => {
    setWasPlayed(true);
    // Удаляем autoplay attr, потому что на некоторых устройствах бывает двойной звук при воспроизведении аудио
    try {
      const audio = document.querySelector('audio');
      if (audio) {
        audio.removeAttribute('autoplay');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onError = error => {
    console.log('Произошла ошибка при загрузке или воспроизведении аудио', error);
  };

  return (
    <SPlayer>
      {!startGetMedia && (
        <>
          <Player
            url={blobAudio}
            onProgress={handleProgress}
            style={{ display: 'none' }}
            onDuration={duration => setDuration(duration)}
            loop={false}
            playing={isPlaying}
            playbackRate={parseFloat(playbackRate)}
            progressInterval={100}
            ref={player}
            onError={onError}
            onPlay={onPlay}
            width={0}
            height={0}
          />
          <AudioTrack
            setTime={setTime}
            bufferedPercentage={bufferedPercentage}
            playedSeconds={playedSeconds}
            duration={duration}
          />
        </>
      )}
      <AudioControls
        recordUrl={audioLink}
        playbackRate={playbackRate}
        setPlaybackRate={setPlaybackRate}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
    </SPlayer>
  );
};

export default React.memo(Audio, isEqual);
