import { message, Space, TimePicker, Upload } from 'antd';
import Axios from 'axios';
import BraftEditor from 'bf-editor-qolio';
import 'bf-editor-qolio/dist/index.css';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { API_V1_PATH } from 'core/api';
import { apiUrl } from 'core/config';
import { processJsonApiObject } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import { get, isEmpty, isFunction, range, isEqual } from 'lodash';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Paperclip } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { getCurrentUser } from 'redux/selectors/users';
import { ConditionHideCommentCheckbox } from 'components/Comments/CommentsList/Comment/Components/ConditionaHideCommentCheckbox';
import ClearOutlined from '@ant-design/icons/lib/icons/ClearOutlined';
import { LOCALES } from 'core/utils/constants';
import CommentFlags from 'components/Comments/Editor/Components/CommentFlags';
import { setComments, setCommentsToSave } from 'redux/ui/checklistEditor/reducer';
import moment from 'moment';
import UploadedFile from './UploadedFile';
import CommentTemplates from './Components/TemplateSelector/CommentTemplates';
import { deleteFileByIdIndexDB, saveFileIndexDB } from '../../../core/indexDB/indexDB';

const CommentTimestamp = ({
  duration,
  wasPlayed,
  defaultTime,
  setDefaultTime,
  commentStateSeconds,
  playedSeconds,
  commentState,
  setCommentState
}) => {
  const hours = Math.floor(duration / 3600);
  const remainingSeconds = duration % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = Math.floor(remainingSeconds % 60);
  const { metadata } = commentState;
  // При редактировании уже добавленого комментария устанавливаем время
  useEffect(() => {
    if (commentStateSeconds) {
      const minutes = Math.floor(commentStateSeconds / 60);
      const seconds = Math.floor(commentStateSeconds % 60);
      const currentTime = moment(`${minutes}:${seconds}`, 'mm:ss');
      setDefaultTime(currentTime);
    } else {
      setDefaultTime(moment(`0:0`, 'mm:ss'));
    }
  }, [commentStateSeconds]);

  // При изменении времени в <TimePicker> изменяем время по умолчанию
  const handleTimeChange = time => {
    setDefaultTime(moment(time));
  };

  // При проигровании аудио изменяем количество секунд
  useEffect(() => {
    if (!playedSeconds) {
      return;
    }
    const minutes = Math.floor(playedSeconds / 60);
    const seconds = Math.floor(playedSeconds % 60);
    const currentTime = moment(`${minutes}:${seconds}`, 'mm:ss');
    setDefaultTime(currentTime);
  }, [playedSeconds]);

  // При изменении времени пересчитываем количество секунд или если комментарий редактируется изменяем количество секунд у редактируемого комментария
  useEffect(() => {
    if (defaultTime) {
      const [minutes, seconds] = moment(defaultTime)
        .format('mm:ss')
        .split(':')
        .map(Number);
      const totalSeconds = minutes * 60 + seconds;
      if (!isNaN(totalSeconds)) {
        setCommentState({
          ...commentState,
          metadata: {
            ...(metadata?.ratingFlag && { ratingFlag: metadata?.ratingFlag }),
            ...(metadata?.questionId && { questionId: metadata?.questionId }),
            ...{ timestamp: totalSeconds }
          }
        });
      }
    }
  }, [defaultTime]);

  return (
    <span>
      <span>
        <Trans i18nKey="components.timestamp" />
      </span>
      <TimePicker
        format="mm:ss"
        value={defaultTime}
        disabled={!wasPlayed}
        showNow={false} // Отображение кнопки 'Сегодня'
        allowClear={false} // Запрет на очищение поля
        style={{
          marginLeft: '10px',
          width: '90px'
        }}
        disabledHours={() => []} // Отключаем часы
        disabledMinutes={selectedHour => (selectedHour === hours ? range(minutes + 1, 60) : [])} // Отключаем минуты превышающие продолжительность звонка
        disabledSeconds={
          (selectedHour, selectedMinute) =>
            selectedHour === hours && selectedMinute === minutes ? range(seconds + 1, 60) : [] // Отключаем секунды превышающие продолжительность звонка
        }
        onChange={handleTimeChange}
      />
    </span>
  );
};

const Editor = React.forwardRef(
  (
    {
      commentState,
      setCommentState,
      setEmp,
      showTemplates,
      showAttachments,
      stopPlaying,
      showFlags,
      allowHidden,
      // allowSelectTimestamp,
      playedSeconds,
      isTimestampAdded,
      actionsComponent = null,
      id,
      itsGeneralComment = false
    },
    ref
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [defaultTime, setDefaultTime] = useState(null);
    const commentStateSeconds = commentState?.metadata?.timestamp;
    const [editorState, setEditorState] = useState(
      BraftEditor.createEditorState(commentState?.text)
    );
    const preferedLocale = useSelector(state => getCurrentUser(state)['prefered-locale']);
    const comments = useSelector(state => state.uiChecklistEditor.comments);
    const { duration, wasPlayed, currentCallId } = useSelector(state => state.uiRecordPlayer);
    const questionIdToAppealObjection = useSelector(
      state => state.uiChecklistManager.questionIdToAppealObjection
    );
    const { commentsToSave, clearFlag } = useSelector(state => state.uiChecklistEditor);
    const currentUser = useSelector(state => getCurrentUser(state));
    const { uploadedFiles = [] } = commentState;
    const organizationInfo = useSelector(state => state.organizationInfo.organization, isEqual);
    const pauseAudioOnCommenting = get(
      organizationInfo,
      'settings.pauseAudioOnCommenting.enabled',
      false
    );

    useImperativeHandle(ref, () => ({
      setEditorState: commentState =>
        setEditorState(BraftEditor.createEditorState(commentState?.text)),
      editorState
    }));

    const onDeleteFile = async id => {
      setCommentState({
        ...commentState,
        uploadedFiles: commentState?.uploadedFiles.filter(({ id: fileId }) => fileId !== id),
        uploadedFilesIds: commentState.uploadedFilesIds
          ?.filter(fileId => fileId !== id)
          .map(({ id }) => id)
      });
      await deleteFileByIdIndexDB(id);
    };

    const uploadHandler = async ({ file, onError, onProgress }) => {
      // TODO: upload file here
      const headers = {
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
        'content-type': 'multipart/form-data'
      };
      const uploadEndpoint = `${apiUrl}${API_V1_PATH}/organization/uploaded_files`;
      const onUploadProgress = ({ total, loaded }) => {
        const percent = Math.round((loaded * 100) / total);
        onProgress({ percent });
      };
      const body = new FormData();
      body.append('file', file);
      body.append('name', file.name);
      const hide = message.loading(t('integrationsSettingsPage.manageUserAccounts.sendingFile'), 0);
      try {
        const response = await Axios.post(uploadEndpoint, body, { headers, onUploadProgress });
        hide();
        const uploadedFile = processJsonApiObject(response.data.data);
        await saveFileIndexDB({ ...uploadedFile });
        await saveFileIndexDB({ ...uploadedFile });
        dispatch(uploadedFilesResource.actions.loadByIdSucceed(uploadedFile));
        setCommentState({
          ...commentState,
          uploadedFiles: [...(commentState?.uploadedFiles || []), uploadedFile]
        });
      } catch (error) {
        console.log(error);
        hide();
        handleError(error?.response?.status, error?.response?.data);
        onError(error);
      }
    };

    const beforeUpload = file => {
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isLt2M) {
        message.error(t('components.commentsMessages.uploadError'), 4);
      }

      return isLt2M;
    };

    const extendControls = [
      {
        key: 'attachment',
        type: 'component',
        component: (
          <SRow display="inline-flex" width="100%">
            {showAttachments && (
              <SCol flex="none">
                <Upload
                  showUploadList={false}
                  accept="image/*"
                  beforeUpload={beforeUpload}
                  customRequest={uploadHandler}
                >
                  <button
                    type="button"
                    className="control-item button upload-button"
                    data-title={t('general.uploadImage')}
                  >
                    <Icon icon={Paperclip} />
                  </button>
                </Upload>
              </SCol>
            )}
            {showFlags && commentState?.commentType !== 'comment_reply' && (
              <SCol
                flex="auto"
                display="inline-grid"
                justifyContent="flex-end"
                alignItems="flex-start"
                paddingTop="5px"
              >
                <CommentFlags commentState={commentState} setCommentState={setCommentState} />
              </SCol>
            )}
          </SRow>
        )
      }
    ];

    const handleEditorChange = editorState => {
      const isEmptyText = isEmpty(editorState.toText().trim());
      setEditorState(editorState);
      if (!!setEmp) {
        setEmp(isEmptyText);
      }

      if (isFunction(setCommentState)) {
        setCommentState({ ...commentState, text: isEmptyText ? '' : editorState.toHTML() });
      }
    };

    const toogleHidden = () => {
      const { hidden: isHidden } = commentState;
      setCommentState({ ...commentState, hidden: !isHidden });
    };

    const handleFocusOut = editorState => {
      if (!!id) {
        dispatch(setComments({ [id]: editorState }));
      }
      if (!isEmpty(questionIdToAppealObjection)) {
        return;
      }
      if (clearFlag) {
        return;
      }
      if (!commentState?.text && commentState?.uploadedFilesIds?.length === 0) {
        return;
      }

      const commentParams = {
        text: commentState?.text,
        id: id || commentState?.id,
        reviewId: undefined,
        callId: undefined,
        metadata: {
          ...{ ratingFlag: commentState?.metadata?.ratingFlag },
          ...(id && { questionId: id }),
          ...(isTimestampAdded && { timestamp: commentState?.metadata?.timestamp })
        },
        authorId: currentUser.id,
        hidden: commentState?.hidden,
        commentType: 'review_comment',
        uploadedFilesIds: uploadedFiles.map(({ id }) => id)
      };

      dispatch(setCommentsToSave(commentParams));
    };

    let comment = editorState.getSelection().hasFocus ? editorState : comments[id] || '';
    // if (!comment && !editorState.getSelection().hasFocus && !comments.hasOwnProperty(id)) {
    //   console.log("comments1", editorState.toText());
    //   comment = editorState;
    // }
    if (!editorState.getSelection().hasFocus && editorState.toText()) {
      comment = editorState;
    }

    // useEffect(() => {
    //   if (!isEmpty(questionIdToAppealObjection)) {
    //     return;
    //   }
    //   if (clearFlag) {
    //     return;
    //   }
    //   if (!commentState?.text && commentState?.uploadedFilesIds?.length === 0) {
    //     return;
    //   }
    //
    //   const commentParams = {
    //     text: commentState?.text,
    //     id: id || commentState?.id,
    //     reviewId: undefined,
    //     callId: undefined,
    //     metadata: {
    //       ...{ ratingFlag: commentState?.metadata?.ratingFlag },
    //       ...(id && { questionId: id }),
    //       ...(isTimestampAdded && { timestamp: commentState?.metadata?.timestamp })
    //     },
    //     authorId: currentUser.id,
    //     hidden: commentState?.hidden,
    //     commentType: 'review_comment',
    //     uploadedFilesIds: uploadedFiles.map(({ id }) => id)
    //   };
    //
    //   dispatch(setCommentsToSave(commentParams));
    // }, [commentState]);

    // console.log('commentState', commentState);
    // console.log('commentsToSave', commentsToSave);

    return (
      <>
        <SCol span={24}>
          {showTemplates && commentState?.commentType !== 'comment_reply' && (
            <SCol
              style={{
                width: '46px',
                position: 'absolute',
                left: '174px',
                top: '3px',
                zIndex: 2 // При использовании zIndex: 1 кнопка добавить шаблон становиться не кликабельной
              }}
            >
              <CommentTemplates
                commentState={commentState}
                setCommentState={setCommentState}
                setEditorState={setEditorState}
              />
            </SCol>
          )}
          <BraftEditor
            value={comment}
            placeholder={t('components.commentsPanel.commentPlaceholder')}
            language={preferedLocale === LOCALES.RU.value ? LOCALES.RU.value : LOCALES.EN.value}
            controls={['bold', 'link', 'emoji', { key: 'remove-styles', text: <ClearOutlined /> }]}
            stripPastedStyles
            onFocus={() =>
              stopPlaying ? (pauseAudioOnCommenting ? dispatch(stopPlaying()) : null) : null
            }
            onChange={handleEditorChange}
            draftProps={{ spellCheck: true }}
            extendControls={extendControls}
            onBlur={handleFocusOut}
          />
        </SCol>

        {uploadedFiles?.length > 0 && (
          <SCol span={24}>
            <SRow type="flex" width="100%" paddingBottom="8px">
              {uploadedFiles?.map(file => (
                <UploadedFile
                  key={file?.id}
                  uploadedFile={file}
                  allFiles={commentState?.uploadedFiles}
                  onDelete={onDeleteFile}
                />
              ))}
            </SRow>
          </SCol>
        )}

        <SCol span={24}>
          <SRow type="flex" display="flex" justifyContent="space-between" gutter={[8, 8]}>
            <Space direction="horizontal" size={[8, 0]}>
              {allowHidden && (
                <SCol display="flex" flex="none" alignItems="center">
                  <ConditionHideCommentCheckbox
                    checked={commentState?.hidden}
                    onChange={toogleHidden}
                  >
                    {t('components.commentsPanel.buttons.hidden')}
                  </ConditionHideCommentCheckbox>
                </SCol>
              )}

              {/* Если у плеера есть id звонка то отображаем область с временем */}
              {/* allowSelectTimestamp */}
              {currentCallId && !itsGeneralComment && (
                <SCol flex="none">
                  <CommentTimestamp
                    wasPlayed={wasPlayed}
                    duration={duration}
                    defaultTime={defaultTime}
                    setDefaultTime={setDefaultTime}
                    commentStateSeconds={commentStateSeconds}
                    commentState={commentState}
                    setCommentState={setCommentState}
                    playedSeconds={playedSeconds}
                    isTimestampAdded={isTimestampAdded}
                  />
                </SCol>
              )}
            </Space>

            {actionsComponent && (
              <SCol display="flex" flex="auto" justifyContent="end">
                <SRow
                  display="flex"
                  marginLeft="auto"
                  width="max-content"
                  justifyContent="end"
                  className="BraftEditor-actions"
                >
                  <Space value={4}>{actionsComponent}</Space>
                </SRow>
              </SCol>
            )}
          </SRow>
        </SCol>
      </>
    );
  }
);

export default React.memo(Editor);
