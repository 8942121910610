import { Alert, Button, Checkbox, Col, DatePicker, Modal, Row, Select, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import { DATE_PICKER_MODES, SYNC_DATA_PERIODS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';
import CustomDatePicker from '../../../../components/DatePicker/DatePicker';
import { isEmpty } from 'lodash';

const { Option } = Select;

const SyncPeriod = ({
  disabled,
  defaultValue,
  onOk,
  okText,
  type = null,
  okProps = {},
  syncAtTime = '',
  integration_id = null
}) => {
  const mounted = React.useRef(false);
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  // const [internal, setInternal] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [contr, setContr] = useState(false);
  const [date, setDate] = useState(null);
  const [isOldDataUpdate, setIsOldDataUpdate] = useState(false);
  const handleOk = async () => {
    setLoading(true);
    if (type === 'amocrm' || type === 'naumen' || type === 'mango' || type === 'edna') {
      await onOk({ ...date, update_old_communications: isOldDataUpdate?.target?.checked });
    } else {
      await onOk(value);
    }
    mounted.current && setLoading(false);
  };
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <SCard>
      {disabled && type != 'amocrm' && type != 'mango' && type !== 'naumen' && type !== 'edna' && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message={t('integrationsSettingsPage.infoAlert.message')}
          description={t('integrationsSettingsPage.infoAlert.description', { time: syncAtTime })}
        />
      )}
      {disabled && (type == 'amocrm' || type == 'mango' || type === 'naumen' || type === 'edna') && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message="Синхронизация в данный момент уже выполняется. Дождитесь завершения или попробуйте обновить страницу."
        />
      )}
      {!disabled && (type == 'amocrm' || type == 'mango' || type === 'naumen' || type === 'edna') && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message="Пожалуйста задайте диапазон для синхронизации данных. Обратите внимание, чем больше заданный диапазон, тем больше времени займет синхронизация. Вы всегда сможете увеличить диапазон синхронизируемых данных в настройках интеграции."
        />
      )}

      <Row align="middle" justify="space-between">
        <Col>
          {type === 'amocrm' || type === 'naumen' || type === 'mango' || type === 'edna' ? (
            <Row style={{ alignItems: 'center' }}>
              <Col style={{ width: '250px' }}>
                <CustomDatePicker
                  onChange={setDate}
                  value={{
                    symbolicTimeRange: date?.symbolicTimeRange,
                    timeFrom: date?.timeFrom,
                    timeTo: date?.timeTo
                  }}
                  mode={DATE_PICKER_MODES.custom.integrations}
                />
              </Col>
              <Col style={{ marginLeft: '20px' }}>
                <Checkbox value={isOldDataUpdate} onChange={setIsOldDataUpdate} >
                  {t('integrationsSettingsPage.checkboxIsUpdateOldData')}
                </Checkbox>
              </Col>
            </Row>
          ) : (
            <Select
              size="large"
              style={{ width: '380px' }}
              onChange={setValue}
              value={value}
              placeholder={t('integrationsSettingsPage.selectSyncPeriod')}
            >
              {Object.values(SYNC_DATA_PERIODS).map(period => (
                <Option value={period.key} key={period.key}>
                  {t(period.name)}
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col>
          <SButton
            width="230px"
            type="primary"
            size="big"
            onClick={handleOk}
            loading={loading}
            disabled={isEmpty(value || date) || disabled}
            {...okProps}
          >
            {okText}
          </SButton>
        </Col>
      </Row>
      {/* {type == 'mango' && (
        <Col>
          <Checkbox
            checked={contr ? internal : intern}
            style={{ marginLeft: '4px' }}
            onClick={toggleInternal}
          >
            {t('integrationsSettingsPage.syncInternal')}
          </Checkbox>
        </Col>
      )} */}
    </SCard>
  );
};

export default SyncPeriod;
