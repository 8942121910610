import { Button, Col, message, Radio, Row } from 'antd';
import QuestionModal from 'components/Questions/QuestionModal';
import PromptModal from 'components/Prompts/PromptModal';
import SCard from 'components/Standard/SCard';
import { SCALE_TYPES } from 'core/utils/constants';
import objectsDifference from 'core/utils/objectsDifference';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as questionActions } from 'redux/lists/questionsList';
import {
  actions as promptActions,
  operations as promptsListOperations
} from 'redux/lists/promptsList';
import { questionsResource } from 'redux/resources/questions';
import { promptsResource } from 'redux/resources/prompts';
import { setCurrentList } from 'redux/ui/checklistsAndQuestionsPage/reducer';
import { setEditingQuestion } from 'redux/ui/questionModal/reducer';
import { setEditingPrompt } from 'redux/ui/promptModal/reducer';
import uniqid from 'uniqid';
import SCol from 'components/Standard/SCol';
import { operations as promptsQuestionAllListOperations } from 'redux/lists/promptsQuestionsAllList';
import AiIcon from 'assets/images/ai-icon.svg';

const ChecklistsAndQuestionsFilters = ({
  history,
  setCurrentList,
  currentList,
  setEditingQuestion,
  setEditingPrompt,
  createQuestion,
  createPrompt,
  updateQuestion,
  updatePrompt,
  questionsByIds,
  promptsByIds,
  onQuestionCreate,
  onPromptCreate,
  updateQuestionsAvailability,
  loadPrompts
}) => {
  const { t } = useTranslation();
  const defaultPromptCategory = 'base_prompts';
  const prompts = useSelector(state => state.promptsResource.byIds);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const onAddPrompt = () => {
    setEditingPrompt({
      id: uniqid(),
      organization_id: organizationId,
      operator_id: currentUserId,
      category: defaultPromptCategory
    });
  };

  const onAddQuestion = () => {
    setEditingQuestion({
      id: uniqid(),
      scaleType: SCALE_TYPES.max_5.type,
      colorZones: SCALE_TYPES.max_5.colorZones,
      ratingValues: SCALE_TYPES.max_5.numbers
    });
  };

  const onCreateChecklist = () => {
    history.push('/checklist-settings');
  };
  const createNewPrompt = async question => {
    const promptName = question.promptName ? question.promptName : question.name;
    const { promptValue } = question;

    return createPrompt({
      operator_id: currentUserId,
      organization_id: organizationId,
      name: promptName,
      value: promptValue,
      binding_type: 'question'
    });
  };

  const updatePromptWithQuestions = async (
    promptId,
    addQuestions = [],
    deleteQuestions = [],
    promptValue
  ) => {
    const actions = {};

    if (addQuestions.length > 0) {
      actions.add_questions = addQuestions;
    }

    if (deleteQuestions.length > 0) {
      actions.delete_questions = deleteQuestions;
    }
    if (promptValue) {
      actions.update_value = promptValue;
    }
    await updatePrompt({
      id: promptId,
      actions
    });

    await updateQuestionsAvailability({
      organization_id: organizationId,
      type: 'questions',
      availability: 'all_availability'
    });

    await loadPrompts({
      organization_id: organizationId
    });
  };

  const onSubmitQuestion = async ({ question, setPromptsAPILoading }) => {
    // Создаём новый промпт, Привязываем промпт из библиотеки к критерию, Изменяем промпт у критерия, Отвязываем промпт от критерия, Привязываем промпт к критерию
    let newPrompt = '';
    const {
      promptRadio = null,
      promptLibrary = null,
      selectedPromptId = null,
      selectedPromptValue = null
    } = question;
    const questionPrompt =
      Object.values(prompts).find(item =>
        item.questions.some(questionItem => questionItem.questionId === question.id)
      ) || {};
    let questionPromptId = '';
    let questionPromptQuestionId = '';
    if (Object.values(questionPrompt).length !== 0) {
      // prompt id
      questionPromptId = questionPrompt.id;
      // question id
      questionPromptQuestionId = questionPrompt.questions[0].questionId;
    }

    const isNew = !questionsByIds[question.id];
    const newQuestion = isNew
      ? await createQuestion(question)
      : await updateQuestion({
          ...objectsDifference(question, questionsByIds[question.id], [
            'colorZones',
            'standardComments'
          ]),
          id: question.id
        });

    if (newQuestion) {
      if (question.useAI) {
        // action add prompt
        if (promptRadio === 'createPrompt') {
          // Создаём новый промпт
          newPrompt = await createNewPrompt(question);
        }
        // action select prompt from library
        if (promptRadio === 'selectPrompt') {
          // Привязываем промпт из библиотеки к критерию
          await updatePromptWithQuestions(promptLibrary, [newQuestion.id], [], selectedPromptValue);
        }
      } else {
        // change prompt
        if (selectedPromptId && questionPromptId !== selectedPromptId) {
          // delete question from prompt
          await updatePromptWithQuestions(questionPromptId, [], [questionPromptQuestionId]);
          // add new question for prompt
          if (selectedPromptValue !== question.value) {
            await updatePromptWithQuestions(
              selectedPromptId,
              [question.id],
              [],
              selectedPromptValue
            );
          } else {
            await updatePromptWithQuestions(selectedPromptId, [question.id], []);
          }
        }
        if (questionPromptId && selectedPromptId === null) {
          if (selectedPromptValue !== question.value) {
            await updatePromptWithQuestions(questionPromptId, [], [], selectedPromptValue);
          } else {
            await updatePromptWithQuestions(questionPromptId, [], [questionPromptQuestionId]);
          }
        }
      }
      onQuestionCreate({ questionId: newQuestion.id });
      if (newPrompt) {
        // Привязываем промпт к критерию
        onPromptCreate({ id: newPrompt.id });
        await updatePromptWithQuestions(newPrompt.id, [newQuestion.id], []);
      }
      message.success(
        `${t('checklistsPage.messages.question')} ${question.name} ${
          isNew
            ? t('checklistsPage.messages.questionSuccessfullyCreated')
            : t('checklistsPage.messages.questionSuccessfullyUpdated')
        } `
      );
      // Завершено
      setPromptsAPILoading(false);
      setEditingQuestion({});
    } else {
      message.error(
        isNew
          ? t('checklistsPage.messages.createQuestionfailed')
          : t('checklistsPage.messages.updateQuestionfailed')
      );
      setPromptsAPILoading(false);
    }
  };

  const onSubmitPrompt = async ({ prompt, setPromptsAPILoading }) => {
    const isNew = !promptsByIds[prompt.id];
    const newPrompt = isNew ? await createPrompt(prompt) : '';

    if (newPrompt) {
      message.success(
        `${t('components.promptsList.messages.create.name')} '${prompt.name}' ${t(
          'components.promptsList.messages.create.success'
        )}`
      );
      onPromptCreate({ id: newPrompt.id });
      loadPrompts({
        organization_id: organizationId
      });
      setPromptsAPILoading(false);
      setEditingPrompt({});
    } else {
      message.error(t('components.promptsList.messages.create.error'));
    }
  };

  return (
    <SCard rounded={false}>
      <Row type="flex" justify="space-between">
        <SCol display="flex" flex="auto">
          <Radio.Group onChange={({ target }) => setCurrentList(target.value)} value={currentList}>
            <Radio.Button value="checklists">{t('checklistsPage.buttons.checklists')}</Radio.Button>
            <Radio.Button value="questions">{t('checklistsPage.buttons.questions')}</Radio.Button>
            <Radio.Button value="prompts">
              <img
                src={AiIcon}
                alt="ai-icon"
                style={{ marginRight: '5px', paddingBottom: '2px' }}
              />
              {t('checklistsPage.buttons.prompts')}
            </Radio.Button>
          </Radio.Group>
        </SCol>
        <Col>
          <Row type="flex" justify="end" gutter={[8, 0]}>
            <Col>
              <Button type="default" onClick={onAddPrompt}>
                {t('checklistsPage.buttons.createPrompt')}
              </Button>
            </Col>
            <Col>
              <Button type="default" onClick={onAddQuestion}>
                {t('checklistsPage.buttons.createQuestion')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onCreateChecklist}>
                {t('checklistsPage.buttons.createChecklists')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <QuestionModal onSubmit={onSubmitQuestion} />
      <PromptModal onSubmit={onSubmitPrompt} />
    </SCard>
  );
};

const mapStateToProps = state => ({
  currentList: state.uiChecklistsAndQuestionsPage.currentList,
  questionsByIds: state.questionsResource.byIds,
  promptsByIds: state.promptsResource.byIds
});

const mapDispatchToProps = {
  setCurrentList,
  setEditingQuestion,
  setEditingPrompt,
  createQuestion: questionsResource.operations.create,
  createPrompt: promptsResource.operations.create,
  updateQuestion: questionsResource.operations.updateById,
  updatePrompt: promptsResource.operations.updateById,
  onQuestionCreate: questionActions.onQuestionCreate,
  onPromptCreate: promptActions.onPromptCreate,
  updateQuestionsAvailability: promptsQuestionAllListOperations.load,
  loadPrompts: promptsListOperations.load
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChecklistsAndQuestionsFilters)
);
